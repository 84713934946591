<template>
    <diV class="col-md-12 col-sm-12">
        <s-toolbar dense dark color="primary" @close="close()" close label="Editar y Eliminar" />
        <v-card outlined class="pa-md-4 mx-lg-auto">
            <v-row>
                <v-col lg="12" cols="12" style="padding-bottom: 10px;">
                    <v-tabs v-model="ItemSelect">
                        <v-tab href="#ProductionLine">Lineas</v-tab>
                        <v-tab href="#CreateTable">Estaciones de trabajo</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="ItemSelect">
                        <v-tab-item :value="'ProductionLine'">
                            <production-line/>
                        </v-tab-item>
                        <v-tab-item :value="'CreateTable'">
                            <create-table />
                        </v-tab-item>
                    </v-tabs-items>
                </v-col>
            </v-row>
        </v-card>
    </diV>
</template>

<script>

    import ProductionLine from '../ProductionCategory/FrzProductionLine.vue'
    import CreateTable from '../FrzCreationTable/FrzCreationTable.vue'
    
    export default{
        components:{ProductionLine, CreateTable},
        props: {},
        data: () => ({
            ItemSelect: "tab-Funciones",
        }),
        methods:{
            close()
            {
                this.$emit("close");
            }
        }
    };
</script>