<template>
    <div>
        <s-crud
            title="Linea de Produccion"
            height="300"
			:config="config"
			:filter="filter"			
			@save="save($event)"
			edit
			remove
			search-input>
            <template v-slot="props">
                <v-container>
                    <v-row>
                        <v-col cols="3" lg="3" md="6" sm="12">
                            <s-text
                                label="Descripcion"
                                v-model="props.item.LncDescription"
                                ref="txtLncDescription">

                            </s-text>
                        </v-col>

                        <v-col cols="4" lg="4" md="6" sm="12">
                            <s-select-definition
								label="Cultivo"
								v-model="props.item.TypeCultive"
                                :def="1173"
                                
                            ></s-select-definition>
                        </v-col>

                        <v-col cols="3" lg="3" md="6" sm="12">							
							<s-select
                                :items="ProductionCategory"
                                v-model=props.item.CecID 
                                item-value="CecID"
                                item-text="CecDescription"
                                label="Salida de Congelamiento"
                                                                
                                ref="txtCecID"
							>
							</s-select>
						</v-col>  
                        <v-col cols="3" lg="3" md="6" sm="12"> 
                            <s-select-definition 
                                :def=1154 label="Estado" 
                                v-model=props.item.LncState>
                            </s-select-definition>  
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template v-slot:filter>
				<v-container>
					<v-row justify="center">
                        <v-col cols="2">
                            <s-select-definition
                                :def="1173" 
                                label="Cultivo"
                                v-model="filter.TypeCultive"
                                 
                            />
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template v-slot:LncState="{row}">
                <v-icon :color="row.LncState == 1 ? 'success': 'default'">mdi-checkbox-blank-circle</v-icon>
            </template>
        </s-crud>
    </div>
</template>
<script>
import _sProductionLine from '@/services/FrozenProduction/ProductionLine.js';
import _sProductionCategory from '../../../services/FrozenProduction/ProductionCategory.js';

import _sFrzTypeExitCategoryService from "@/services/FrozenProduction/FrzTypeExitCategory.js"

export default{
    data(){
        return {
            ProductionCategory: [],
            config:{
                model:{
                    LncID:"ID",
                    LncState: "LncState"
                },service: _sProductionLine,
                headers:[
                    {text:"ID", value:"LncID"},
                    {text:"Descripcion", value:"LncDescription"},
                    {text:"Tipo de Salida Congelamiento", value:"CecDescription"},
                    {text:"Cultivo", value: "TypeCultiveN"},
                    {text:"Estado", value:"LncState", align:"center", sorteable:true},
                ]
            },
            filter:{}
        }
    }, 
    created()
    {
        this.initialize()
    },
    methods:{

        inputProdCategory(){
                
            _sProductionCategory.list( this.$fun.getUserID()).then(r =>{
                if(r.status == 200)
                {
                    this.ProductionCategory = r.data;              
                }
            });   
        },

        save(item)
        { 
            
            if(item.LncDescription == ""){
                this.$fun.alert("Ingrese descripcion", "warning");
                this.$refs.txtLncDescription.focus();
                return;
            }
            if(item.CecID == ""  || item.CecID == undefined)
            {
                this.$fun.alert("Ingrese Tipo de Salida de Congelamiento", "warning");
                this.$refs.txtCategoria.focus();
                return;
            }
            item.save();
        },

        initialize()
        {
            _sProductionCategory.list(this.$fun.getUserID()).then(r =>{
                if(r.status == 200)
                {
                    this.ProductionCategory = r.data;     
                                    
                }
            });            
        }
    }
}
</script>
