import Service from "../Service";

const resource = "FrzCreateTable/";

export default {
    saveNumTable(obj, NumTable, requestID) {
        return Service.post(resource + "save", obj, {
            params: { requestID: requestID,
                        NumTable: NumTable
             },
        });
    },

    save(obj, requestID) {
        return Service.post(resource + "saveupdate", obj, {
            params: { requestID: requestID
             },
        });
    },
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },
    list(requestID) {
        return Service.post(resource + "list", {}, {
            params: { requestID: requestID },
        });
    },
}; 