import Service from '../Service';

const resource = "productionline/";


export default{

    save(prd, requestID) {
        return Service.post(resource + "save", prd, {
            params: { requestID: requestID },
        });
    },

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    list(obj, requestID) {
        return Service.post(resource + "list", obj, {
            params: { requestID: requestID },
        });
    },

    gettypecultive(obj, requestID) {
        return Service.post(resource + "gettypecultive", obj, {
            params: { requestID: requestID },
        });
    },
};