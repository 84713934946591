<template>
	<div>
		<!-- -->
		<s-crud
			title="Estaciones de Trabajo"
			:config="config"
			:filter="filter"
			height="300"
			 @save="save($event)"
			edit 
			remove
			search-input
		> 
			<template v-slot="props">
				<v-container>
					<v-row>
					
                        <v-col cols="4" lg="4" md="6" sm="12">
                            <s-text
								label="Nombre"
								v-model="props.item.CttName"
								ref="txtNombre"
							></s-text>
                        </v-col>

                        <v-col cols="4" lg="4" md="6" sm="12">
							<s-text
								label="Capacidad"
								v-model="props.item.CttCapacity"
								ref="txtCapacidad"
								type="number"
							></s-text>
						</v-col>

                        <v-col cols="4" lg="4" md="6" sm="12">
							<s-select-definition
								label="Posición"
								v-model="props.item.CttPosition"
                                :def="1426"
								ref="txtPosition"
                            ></s-select-definition>
						</v-col>

                        <v-col cols="4" lg="4" md="6" sm="12">
							<s-select
								label="Lineas"
								:items="listLineas"
								v-model="props.item.LncID"
								item-value="LncID"
								item-text="LineDescriptionName"      
								ref="txtLinea"        
							></s-select>
                        </v-col>

                        <v-col cols="4" lg="4" md="6" sm="12">
                            <s-select-definition
								label="Estado"
								v-model="props.item.CttStatus"
                                :def="1154"
                            ></s-select-definition>
                        </v-col>

                    </v-row>
				</v-container>
			</template>
			<template v-slot:CttStatus="{ row }">
				<v-chip x-small=""  :color="row.CttStatus == 1 ? 'success': 'error'" >
					{{ row.CttStatus == 1 ? 'Activo': 'Inactivo' }}
				</v-chip>
			</template>

			<template v-slot:CttInUse="{ row }">
				<v-chip x-small=""  :color="row.CttInUse == 1 ? 'success': 'error'" >
					{{ row.CttInUse == 1 ? 'En uso': 'Sin Usar' }}
				</v-chip>
			</template>
			<template v-slot:filter>
				<v-container>
					<v-row justify="center">
						<v-col cols="2" lg="2" md="6" sm="12">
                            <s-select-definition
								label="Cultivo"
								v-model="filter.typeCultive"
                                :def="1173"
                                @input="inputProdCategory($event)"
								ref="txtCultive"
                            ></s-select-definition>
                        </v-col>
						<v-col cols="3" lg="3" md="6" sm="12">
							<s-select
								:items="ProductionLine"
								item-value="LncID"
								item-text="LncDescription"
								label="Linea"
								v-model="filter.LncID"
                                :cultiveID="typeCultive"
								clearable
                                ref="txtCategoria"
							/>	
						</v-col>
						<!-- <v-col cols="3" lg="3" md="6" sm="12">
							<s-select
								:items="ProductionCategory"
								item-value="PdcID"
								item-text="PdcDescription"
								label="Tipo de Congelamiento"
								v-model="filter.PdcID"
                                :cultiveID="typeCultive"
								clearable
                                ref="txtCategoria"
							/>	
						</v-col> -->
					</v-row>
				</v-container>
			</template>
		</s-crud>
	</div>
</template>



<script>
	import _sCreationTableService from "@/services/FrozenProduction/FrzCreateTableService.js"
	import _sFrzTypeExitCategoryService from "@/services/FrozenProduction/FrzTypeExitCategory.js"
	import _sProductionLine from '@/services/FrozenProduction/ProductionLine.js';
	export default {
		data() {
			return {
				CttProjectedDate: true,
				ProductionCategory: [],
				ProductionLine: [],
				typeCultive: 0,
				config: {
					model: {
						CttID: "ID",
						CttStatus:"",
						CttInUse: ""
					},
					service: _sCreationTableService,
					headers: [
						{text: "ID", value: "CttID"},
						{text: "Estaciones de Trabajo", value: "CttName"},
						{text: "Capacidad", value: "CttCapacity"},
						{text: "Posición", value: "DedDescription"},
						{text: "Estado", value: "CttStatus"},
                        {text: "Linea", value: "LncDescription"},
						{text: "Tipo de Salida Congelamiento", value :"CecDescription"},
						{text: "Uso en linea", value :"CttInUse"}
					]
				},
				filter: { CttName: 0, typeCultive: 0},
				listLineas:[],
			}
		},

		methods: {
			
			save(item) {
                if(item.CttName == "")
				{
					this.$fun.alert("Ingrese el nombre", "warning");
					this.$refs.txtNombre.focus();
					return;
				}

				if(item.CttCapacity == "")
				{
					this.$fun.alert("Ingrese capacidad", "warning");
					this.$refs.txtCapacidad.focus();
					return;
				}
				if((item.CttCapacity > 6) || (item.CttCapacity <= 0))
				{
					this.$fun.alert("Ingrese capacidad valida", "warning");
					this.$refs.txtCapacidad.focus();
					return;
				}
				if(item.LncID == 0  || item.LncID == undefined)
				{
					this.$fun.alert("Ingrese una Linea", "warning");
					this.$refs.txtLinea.focus();
					return;
				}
		
				item.save();	
			},
			inputProdCategory(typeCultive){
				_sFrzTypeExitCategoryService.list( { typeCultive }, this.$fun.getUserID()).then(r =>{
					if(r.status == 200)
					{
						this.ProductionCategory = r.data;                    
					}
				})
				
				_sProductionLine.gettypecultive( { typeCultive }, this.$fun.getUserID()).then(r =>{
					if(r.status == 200)
					{
						this.ProductionLine = r.data;                    
					}
				})
			}			
			
		},

		created(){

			_sCreationTableService
			.list(this.$fun.getUserID())
			.then((r) => {
				this.listLineas = r.data;				
				if (this.listLineas.length > 0) {
					this.LncID = r.data[0]
					
				}
			});
		}
	}
</script>